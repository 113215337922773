import React from 'react'
import ReactDOM from 'react-dom'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import 'pannellum/src/js/libpannellum'
import 'pannellum/src/js/pannellum'
import 'pannellum/src/css/pannellum.css'

const propsToConfig = [
  'autoLoad',
  'compass',
  'draggable',
  'hotspotDebug',
  //'image',
  'maxHfov',
  'minHfov',
  'mouseZoom',
  'orientationOnByDefault',
  'pitch',
  'sceneFadeDuration',
  'showControls',
  'yaw',
  'maxYaw',
  'minYaw',
  'maxPitch',
  'minPitch',
  //'height',
  //'width',
  'multiRes',
  'multiResMinHfov',
]

const PannellumContext = React.createContext()

function checkChanged(prevObj, curObj, container, propList) {
  let isChanged = false
  for (const prop of propList) {
    const {[prop]: prevValue} = prevObj
    const {[prop]: newValue} = curObj
    container[prop] = newValue
    if (prevValue !== newValue) {
      isChanged = true
    }
  }
  return isChanged
}

const hotSpotRebuildList = ['id','className']
const hotSpotUpdateList = ['pitch', 'yaw']

export function HotSpot(props) {
  const component = React.useContext(PannellumContext)
  const {id, className, pitch, yaw, width, children} = props
  const [div, setDiv] = React.useState()
  const [hotSpotConfig, setHotSpotConfig] = React.useState({
    type: 'custom',
    id,
    cssClass: className,
    pitch,
    yaw,
    width,
  })
  function createTooltipFunc(div) {
    //console.log('createTooltipFunc', id, hotSpotConfig, div)
    setDiv(div)
  }
  hotSpotConfig.createTooltipFunc = createTooltipFunc
  React.useEffect(() => {
    hotSpotConfig.pitch = pitch
    hotSpotConfig.yaw = yaw
  }, [pitch, yaw])

  React.useEffect(() => {
    hotSpotConfig.id = id
    hotSpotConfig.cssClass = className
    hotSpotConfig.createTooltipFunc = createTooltipFunc
    //console.log('adding hotspot', id, hotSpotConfig)
    component.p.addHotSpot(hotSpotConfig)
    return () => {
      //console.log('removing hotspot', id)
      component.p.removeHotSpot(id)
    }
  }, [id, className])
  React.useEffect(() => {
    return () => {
      if (div) {
        //console.log('unmounting children', id)
        ReactDOM.unmountComponentAtNode(div)
      }
    }
  }, [div])
  //console.log('HotSpot:render', id, div)
  React.useEffect(() => {
    if (div && children) {
      //console.log('rendering hotspot', id, div)
      ReactDOM.render(children, div)
    }
  })
  return null
}

function fixupLookatHandler(p, onUpdateLookAt) {
  const renderer = p.getRenderer()
  const origRender = renderer.render
  renderer.render = (...args) => {
    const pitch = p.getPitch()
    const yaw = p.getYaw()
    const hfov = p.getHfov()
    onUpdateLookAt({pitch, yaw, hfov})
    return origRender.apply(renderer, args)
  }
  return p
}

function fixupInit(p, component) {
  const renderer = p.getRenderer()

  function wrapGl(gl) {
    const {useProgram, deleteProgram} = gl
    gl.useProgram = (...args) => {
      const program = args[0]
      component.setState({program})
      //console.log('useProgram', program)
      return useProgram.apply(gl, args)
    }
    gl.deleteProgram = (...args) => {
      component.setState({program: null})
      return deleteProgram.apply(gl, args)
    }
    return gl
  }

  function wrapCanvas(canvas) {
    const {getContext} = canvas
    canvas.getContext = (...args) => {
      const gl = getContext.apply(canvas, args)
      return wrapGl(gl)
    }
    return canvas
  }

  const origInit = renderer.init
  renderer.init = (...args) => {
    //console.log('renderer::wrappedInit')
    //this.init = function(_image, _imageType, _dynamic, haov, vaov, voffset, callback, params) {
    const callback = args[6]
    const rendererContainer = p.getContainer().getElementsByClassName('pnlm-render-container')[0]
    component.setState({rendererContainer})
    if (!component.state.canvas) {
      const canvas = wrapCanvas(rendererContainer.firstChild)
      component.setState({canvas})
      canvas.getContext('experimental-webgl')
    }
    args[6] = (...args) => {
      component.setState({fadeImg: renderer.fadeImg})
      delete renderer.fadeImg
      component.rendererInit()
      callback(...args)
    }
    return origInit.apply(renderer, args)
  }
  return p
}

export class Pannellum extends React.Component {
  static defaultProps = {
    //animationTimingFunction: timingFunction,
    avoidShowingBackground: false,
    autoLoad: false,
    autoRotate: false,
    autoRotateInactivityDelay: -1,
    autoRotateStopDelay: undefined,
    backgroundColor: [0, 0, 0],
    capturedKeyNumbers: [16, 17, 27, 37, 38, 39, 40, 61, 65, 68, 83, 87, 107, 109, 173, 187, 189],
    crossOrigin: 'anonymous',
    disableKeyboardCtrl: false,
    doubleClickZoom: true,
    draggable: true,
    dynamic: false,
    dynamicUpdate: false,
    friction: 0.15,
    haov: 360,
    hfov: 100,
    hotSpotDebug: false,
    keyboardZoom: true,
    maxHfov: 120,
    maxPitch: undefined,
    maxYaw: 180,
    minHfov: 50,
    minPitch: undefined,
    multiResMinHfov: true,
    minYaw: -180,
    mouseZoom: true,
    northOffset: 0,
    orientationOnByDefault: false,
    pitch: 0,
    roll: 0,
    showControls: true,
    showFullscreenCtrl: true,
    showZoomCtrl: true,
    touchPanSpeedCoeffFactor: 1,
    type: 'equirectangular',
    vaov: 180,
    vOffset: 0,
    yaw: 0,
  }

  static propTypes = {
  }

  state = {
    loaded: false,
    program: null,
    canvas: null,
    rendererContainer: null,
    fadeImg: null,
    default: {},
  }

  pannellumRef = React.createRef()

  static getDerivedStateFromProps(props, state) {
    const {
      hfov,
      pitch,
      yaw,
      onMovement,
    } = props
    const newState = {}
    if (onMovement) {
      newState.pitch = pitch
      newState.yaw = yaw
      newState.hfov = hfov
    }
    return newState
  }

  componentDidMount() {
    const {default: defaults} = this.state
    const props = this.props
    const {image, multiRes} = props
    const scenes = {}
    const config = {
      type: 'multires',
      default: defaults,
      scenes,
    }
    propsToConfig.forEach(prop => {
      defaults[prop] = props[prop]
    })
    const p = this.p = fixupInit(fixupLookatHandler(pannellum.viewer(this.pannellumRef.current, config), this.handleUpdateLookAt), this)

    Object.entries(this).forEach(([key, value]) => {
      if (key.match(/^handle.*/) && typeof value === 'function') {
        const eventName = key.substring('handle'.length).toLowerCase()
        p.on(eventName, value)
      }
    })
    p.addScene(image, {basePath: image})
    //console.log('loading first scene')
    p.loadScene(image, p.getPitch(), p.getYaw(), p.getHfov())
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      image,
      multiRes,

      minHfov, maxHfov, hfov,
      minPitch, maxPitch, pitch,
      minYaw, maxYaw, yaw,

      width, height,
    } = this.props
    const {default: defaults} = this.state
    const p = this.p

    if (prevProps.pitch !== pitch || prevProps.yaw !== yaw || prevProps.hfov !== hfov) {
      p.lookAt(pitch, yaw, hfov, 0)
    }
    if (prevProps.minHfov !== minHfov || prevProps.maxHfov !== maxHfov) {
      defaults.minHfov = minHfov
      defaults.maxHfov = maxHfov
    }
    if (prevProps.minPitch !== minPitch || prevProps.maxPitch !== maxPitch) {
      defaults.minPitch = minPitch
      defaults.maxPitch = maxPitch
    }
    if (prevProps.minYaw !== minYaw || prevProps.maxYaw !== maxYaw) {
      defaults.minYaw = minYaw
      defaults.maxYaw = maxYaw
    }

    if (prevProps.image !== image) {
      this.setState({loaded: false})
      p.addScene(image, {basePath: image})
      //console.log('loading new scene')
      p.loadScene(image, pitch, yaw, hfov)
      //p.loadScene(image)
    }
    if (prevProps.width !== width || prevProps.height !== height) {
      p.resize()
    }
  }

  isLoading = () => {
    const {type} = this.props
    if (type !== 'multires') {
      return false
    }
    const {program} = this.state
    if (!program) {
      return false
    }
    //console.log('isLoading:type', type)
    for (const node of program.currentNodes) {
      if (node.level !== 1) {
        continue
      } else if (!node.textureLoaded) {
        return true
      }
    }
    return false
 }

  rendererInit = () => {
    const checkFade = () => {
      const {fadeImg} = this.state
      if (!fadeImg) {
        return
      }
      if (this.isLoading()) {
        requestAnimationFrame(checkFade)
      } else {
        fadeImg.style.opacity = 0;
        setTimeout(() => {
          this.state.rendererContainer.removeChild(fadeImg)
        }, this.props.sceneFadeDuration)
        this.setState({fadeImg: null})
      }
    }
    requestAnimationFrame(checkFade)
  }

  componentWillUnmount() {
    const p = this.p
    if (!p) {
      return
    }
    Object.entries(this).forEach(([key, value]) => {
      if (key.match(/^handle.*/) && typeof value === 'function') {
        const eventName = key.substring('handle'.length).toLowerCase()
        p.off(eventName, value)
      }
    })
    p.destroy()
  }

  handleUpdateLookAt = ({pitch, yaw, hfov}) => {
    if (this.state.pitch !== pitch || this.state.yaw !== yaw || this.state.hfov !== hfov) {
      const movement = {pitch, yaw, hfov}
      //console.log('onMovement', [this.state.pitch, this.state.yaw, this.state.hfov], movement)
      this.setState(movement)
      const {onMovement} = this.props
      if (onMovement) {
        onMovement(movement)
      }
    }
  }

  // private
  pollLoading() {
    const recheck = () => {
      this.setState((state, props) => {
        const {pannellum_loaded, pannellum_loading_scene} = state
        if (!pannellum_loaded) {
          return {}
        }
        const loaded = !this.isLoading()
        if (!loaded) {
          requestAnimationFrame(recheck)
        }
        return {loaded}
      })
    }
    requestAnimationFrame(recheck)
  }

  handleLoad = () => {
    //console.log('handleLoad')
    this.setState({pannellum_loaded: true})
    this.pollLoading()
  }

  handleScenechange = (sceneId) => {
    this.setState({pannellum_loaded: false, pannellum_loading_scene: sceneId})
  }

  handleScenechangefadedone = () => {
    // should not happen with monkey patching
  }

  render() {
    const {children, width, height} = this.props
    const {loaded} = this.state
    return <div width={width} height={height} ref={this.pannellumRef}>
      <PannellumContext.Provider value={this} children={loaded ? children : null}/>
    </div>
  }
}
