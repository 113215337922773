import React from 'react'
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/core/styles'
import DvrUserChooser from '../components/userchooser'

const styles = {
  flex: {
    display: 'flex',
    flexFlow: 'row'
  },
  hflex: {
    position: 'absolute',
    display: 'flex',
    flexFlow: 'column',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
  },
  grow: {
    flexGrow: 1
  }
}

class DvrFront extends React.Component {
  render() {
    const {classes} = this.props
    return (
      <React.Fragment>
        <DvrUserChooser />
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DvrFront))
