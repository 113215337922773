import React from 'react'
import {connect} from 'react-redux'
import {Route, Redirect} from 'react-router'

class PrivateRoute extends React.Component {
  render() {
    const {component: Component, isAuthenticated, ...rest} = this.props

    return <Route {...rest} render={(props) => (
      isAuthenticated === true
      ? <Component {...props} />
      : <Redirect to='/login' />
    )} />
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
