import {DVR_SELECT_OPTION, DVR_SELECT_CAMERA} from '../actions/types'                                                                
import { call, select, put, takeEvery, takeLatest, fork } from 'redux-saga/effects'

function getOptions(modelId) {
  const url = 'https://s3.amazonaws.com/dvr-panobuilder-renders/' + modelId + '/options.json'
  return fetch(url).then((response) => {
    return response.status === 200 ? response.json() : 'error'
  })
}

function getCameraMap(modelId) {
  try {
    const url = 'https://s3.amazonaws.com/dvr-panobuilder-renders/' + modelId + '/camera_map.json'
    return fetch(url).then((response) => {
      return response.status === 200 ? response.json() : 'error'
    })
  } catch(e) {
    return {}
  }
}

function getCameras(modelId) {
  const url = 'https://s3.amazonaws.com/dvr-panobuilder-renders/' + modelId + '/cameras.json'
  return fetch(url).then((response) => {
    return response.status === 200 ? response.json() : 'error'
  })
}

function getConfig(hostname) {
  try {
    const url = 'https://s3.amazonaws.com/dvr-panobuilder-renders/aly-hosts/' + hostname + '.json'
    return fetch(url).then((response) => {
      return response.status === 200 ? response.json() : 'error'
    })
  } catch(e) {
    return {
      projects: []
    }
  }
}

function* fetchConfig() {
  try {
    const config = yield call(getConfig, window.location.hostname)
    yield put({type: 'READ_CONFIG_SUCCEEDED', config})
  } catch (e) {
    console.log('Failed to fetch projects', e)
  }
}

function* fetchModel(action) {
  try {
    const state = yield select((state) => {
      return state
    })

    if (state.modelId !== action.modelId) {
      console.log('MODEL FETCH', action)

      const options = yield call(getOptions, action.modelId)
      const cameras = yield call(getCameras, action.modelId)
      const cameraMap = yield call(getCameraMap, action.modelId)

      yield put({type: 'MODEL_FETCH_SUCCEEDED', model: { options, cameras, cameraMap, modelId: action.modelId }})
    }
  } catch (e) {
    console.log(e)
    yield put({type: 'MODEL_FETCH_FAILED', message: e.message})
  }
}

function* updateMultires(action) {
  const state = yield select((state) => {
    return state
  })

  console.log('CURRENT HASH: ', state.options.hash)

  const url = 'https://s3.amazonaws.com/dvr-panobuilder-renders/' + 
    state.options.modelId + '/' + state.options.hash + (state.options.stereo ? '_L' : '') + '/config.json'

  try {
    console.log('FETCHING: ' + url)
    const response = yield fetch(url)
    if (response.status !== 200) return 'error'

    const json = yield response.json()
    console.log('CURRENT JSON: ', json)

    yield put({type: 'UPDATE_MULTIRES', multires: json.multiRes})
    return json
  } catch (e) {
    console.log('FETCH FAILED: ', e)
  }
}

function* modelSaga() {
  yield takeLatest('FETCH_CONFIG', fetchConfig)
  yield takeLatest('MODEL_FETCH_REQUESTED', fetchModel)
  yield takeLatest(DVR_SELECT_OPTION, updateMultires)
  yield takeLatest('MODEL_FETCH_SUCCEEDED', updateMultires)
  yield takeLatest(DVR_SELECT_CAMERA, updateMultires)
}

export default modelSaga
