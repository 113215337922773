import { fork,all } from 'redux-saga/effects'
import modelSaga from './model'
import authSaga from './auth'
import collaborateSaga from './collaborate'

export default function* rootSaga() {
  yield all([
    collaborateSaga(),
    modelSaga(),
    authSaga()
  ])
}
