import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/AccountCircle'

const styles = {
  flex: {
    flexGrow: 1
  }
}

class DvrLoginCard extends React.Component {
  constructor(props) {
    super(props)
    this.classes = props
  }

  render() {
    const {classes} = this.classes
    return (
      <Card>
        <CardContent>
          <List>
            <ListItem>
              <Avatar><ImageIcon /></Avatar>
              <ListItemText>Customer</ListItemText>
            </ListItem>
            <ListItem>
              <Avatar><ImageIcon /></Avatar>
              <ListItemText>Contractor</ListItemText>
            </ListItem>
            <ListItem>
              <Avatar><ImageIcon /></Avatar>
              <ListItemText>Account Representative</ListItemText>
            </ListItem>
            <ListItem>
              <Avatar><ImageIcon /></Avatar>
              <ListItemText>Administrator</ListItemText>
            </ListItem>
          </List>
        </CardContent>
      </Card>
    )
  }
}

export default withStyles(styles)(DvrLoginCard)
