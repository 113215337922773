import {DVR_SELECT_OPTION, DVR_SELECT_CAMERA,JOIN_SESSION_SUCCESS} from '../actions/types'
import SHA1 from 'sha1-es'

const initialState = {
  i18n: {
    "opt:art": "Design",
    "opt:floor": "Floor",
    "opt:wall": "Wall",
    "opt:bar": "Bar",
    "opt:refrigerator": "Refrigerator",
    "opt:stove": "Stove",
    "opt:backsplash": "Backsplash",
    "opt:cabinet": "Cabinets",
    "opt:finish": "Fixtures",
    "floor-herringbone": "Herringbone",
    "floor-pine": "Pine",
    "floor-oak": "Oak",
    "wall-blue": "Blue",
    "wall-concrete": "Concrete",
    "wall-yellow": "Yellow",
    "opt:luxe": "LUXE Design",
    "OfficeLobby.Wall.KandoGold": "Kando Gold",
    "OfficeLobby.Wall.Mehtani18": "Mehtani18",
    "OfficeLobby.Wall.PuzzlePutty": "Puzzle Putty",
    "LUXE.CONTEMPO": "Contempo",
    "LUXE.USONIA": "Usonia",
    "LUXE.WICKLESSaHighRes": "Wickless",
    "LUXE.WICKLESS": "Wickless",
    "bosch-refrigerator": "Bosch Refrigerator",
    "bosch-refrigeration-b30ib800sp": "Bosch B30IB800SP",
    "ge-refrigerator": "GE Refrigerator",
    "bosch-stove": "Bosch Stove",
    "ge-stove": "GE Stove",
    "matte-white": "Matte White",
    "white-oval-tile": "White Oval Tile",
    "matte-grey": "Matte Grey",
    "matte-black": "Matte Black",
    "brushed-steel": "Brushed Steel",
    "Camera": "Main",
    "Camera.Main": "Main",
    "Camera.Entry": "Entry area",
    "Camera.Bathroom": "Bathroom",
    "Camera.Bedroom": "Bedroom",
    "opt:tablecloth": "Table Cloth",
    "opt:cushion": "Cushion",
    "opt:chair": "Chair",
    "Material.004": "Tablecloth",
    "Material.019": "Cushion",
    "Material.009": "Chair",
  },
  camera: '',
  cameras: {},
  selected: {
  },
  available: {
    objects: {},
    materials: {},
  },
  config: {
    projects: []
  },
	hotSpots: [],
  views: [],
  keyset: '',
  hash: '',
  url: ''
}

function translateName(name) {
  if (name.startsWith('.')) {
    return name.split(':')[1]
  } else {
    return name
  }
}

function calcHash(newState, camera) {
  let cam = camera ? camera : newState.camera
  let keyset = cam + '::'
  let materials = []
  let objects = []
  Object.keys(newState.selected).sort().map(opt => {
    const aCam = newState.cameras[cam]
    if (aCam && aCam.options && aCam.options.includes(opt)) {
      if (opt in newState.available.objects) {
        objects.push(opt)
      } else {
        materials.push(opt)
      }
    }
  })
  objects.map(opt => keyset += opt + '=' + translateName(newState.selected[opt]) + ';')
  materials.map(opt => keyset += opt + '=' + translateName(newState.selected[opt]) + ';')
  newState.keyset = keyset
  const newHash = SHA1.hash(keyset)
  console.log('HASH', newHash)
  return newHash
}

function createHotspot(div, args) {
  let span = document.createElement('span')
  span.innerHTML = args
  div.appendChild(span)
  div.addEventListener('click', function() { console.log('Hot spot') })
}

function camList(cam, camMap) {
  let camList = []
  if (camMap[cam]) {
    Object.keys(camMap[cam]).map(ocam => camList.push({
      name: ocam,
      roll: camMap[cam][ocam][0],
      pitch: camMap[cam][ocam][1],
      yaw: camMap[cam][ocam][2],
    }))
  }
  console.log(camList)
  return camList
}

function calcViews(state) {
  let views = {}
  Object.keys(state.cameras).map(cam => {
    let hash = calcHash(state, cam)
    views[cam] = {
      name: cam,
      hash: hash,
      url: 'https://s3.amazonaws.com/dvr-panobuilder-renders/' + state.modelId + '/' + hash
    }
  })
  return views
}

function options(state = initialState, action) {
  let newState
  //console.log('OPTION REDUCER:', action.type)
  switch (action.type) {
    case 'READ_CONFIG_SUCCEEDED':
      newState = {...state}
      newState.config = action.config
      return newState
    case 'MODEL_FETCH_SUCCEEDED':
      newState = {...state}
      newState.modelId = action.model.modelId
      newState.cameras = action.model.cameras
      newState.cameraMap = action.model.cameraMap
      newState.available = action.model.options
//      newState.selected = {}
      Object.keys(newState.available.objects).map(opt => {
        if (!newState.selected[opt]) {
          let newOpt = newState.available.objects[opt][0]
          if (newOpt.indexOf(':') != -1) newOpt = newOpt.split(':')[1]
          newState.selected[opt] = newOpt
        }
      })
      Object.keys(newState.available.materials).map(opt => {
        if (!newState.selected[opt]) {
          let newOpt = newState.available.materials[opt][0]
          if (newOpt.indexOf(':') != -1) newOpt = newOpt.split(':')[1]
          newState.selected[opt] = newOpt
        }
      })
      newState.camera = Object.keys(newState.cameras)[0]
      newState.minPitch = (newState.cameras[newState.camera] && newState.cameras[newState.camera].minPitch) ? parseInt(newState.cameras[newState.camera].minPitch) : -90
      newState.maxPitch = (newState.cameras[newState.camera] && newState.cameras[newState.camera].maxPitch) ? parseInt(newState.cameras[newState.camera].maxPitch) : 90
      newState.minYaw = (newState.cameras[newState.camera] && newState.cameras[newState.camera].minYaw) ? parseInt(newState.cameras[newState.camera].minYaw) : -180 
      newState.maxYaw = (newState.cameras[newState.camera] && newState.cameras[newState.camera].maxYaw) ? parseInt(newState.cameras[newState.camera].maxYaw) : 180 
      newState.stereo = newState.cameras[newState.camera].stereo
      newState.hash = calcHash(newState, newState.camera)
      newState.url = 'https://s3.amazonaws.com/dvr-panobuilder-renders/' + newState.modelId + '/' + newState.hash 
      newState.multires = null
      newState.hotSpots = camList(newState.camera, newState.cameraMap)
      newState.views = calcViews(newState)
      return newState
    case JOIN_SESSION_SUCCESS:
      newState = {...state, ...action.session}
      newState.stereo = newState.cameras[newState.camera].stereo
      newState.hash = calcHash(newState, newState.camera)
      newState.url = 'https://s3.amazonaws.com/dvr-panobuilder-renders/' + newState.modelId + '/' + newState.hash 
      return newState
    case 'SELECT_MODEL':
      newState = {...state}
      newState.modelId = action.modelId
      return newState
    case 'REMOTE_CAMERA_SET':
    case DVR_SELECT_CAMERA:
      newState = {...state}
      newState.camera = action.value
      newState.hash = calcHash(newState)
      newState.stereo = newState.cameras[newState.camera].stereo
      newState.url = 'https://s3.amazonaws.com/dvr-panobuilder-renders/' + newState.modelId + '/' + newState.hash
      newState.minPitch = (newState.cameras[newState.camera] && newState.cameras[newState.camera].minPitch) ? parseInt(newState.cameras[newState.camera].minPitch) : -90
      newState.maxPitch = (newState.cameras[newState.camera] && newState.cameras[newState.camera].maxPitch) ? parseInt(newState.cameras[newState.camera].maxPitch) : 90
      newState.minYaw = (newState.cameras[newState.camera] && newState.cameras[newState.camera].minYaw) ? parseInt(newState.cameras[newState.camera].minYaw) : -180 
      newState.maxYaw = (newState.cameras[newState.camera] && newState.cameras[newState.camera].maxYaw) ? parseInt(newState.cameras[newState.camera].maxYaw) : 180 
      newState.hotSpots = camList(action.value, newState.cameraMap)
      return newState
    case 'UPDATE_MULTIRES':
      newState = {...state}
      newState.multires = action.multires
      return newState
    case 'REMOTE_OPTION_SET':
    case DVR_SELECT_OPTION:
      newState = {...state}
      newState.selected = {...state.selected}
      newState.selected[action.name] = translateName(action.value)
      newState.hash = calcHash(newState)
      newState.url = 'https://s3.amazonaws.com/dvr-panobuilder-renders/' + newState.modelId + '/' + newState.hash
      newState.views = calcViews(newState)
      return newState
  }
  return state
}

export default options
