import React from 'react'
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import DvrPaymentExpando from '../components/payment-expando'
//import DvrAddressExpando from '../components/address-expando'

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  bootstrapRoot: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#007bff',
    borderColor: '#007bff',
    float:'right',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: '#0069d9',
      borderColor: '#0062cc',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
});

class DvrAccount extends React.Component {
  constructor(props){
    super(props)
    this.AddPaymentCardItem = this.AddPaymentCardItem.bind(this);
    //this.AddAddressItem = this.AddAddressItem.bind(this);
  
    this.state = {
      AddNewComponentCount:0,
      AddNewAdrCount:0,
      AddPaymentComponent: [],
     // AddAddressComponent: [],
    }
  }
  handleChange() {
    console.log('Change')
  }
  AddPaymentCardItem(e){
    console.log('add card'+e.target)
    e.preventDefault();
    console.log('add card'+e.target.name)
    const AddPaymentComponent = this.state.AddPaymentComponent.concat(DvrPaymentExpando);

    const compCount = this.state.AddNewComponentCount+1;
    sessionStorage.setItem('Compcnt',compCount);
    this.setState({ AddPaymentComponent });
    this.setState({ AddNewComponentCount:compCount});
  }
  // AddAddressItem(e){
  //   console.log('add card'+e.target.name)
  //   e.preventDefault();
  //   const AddAddressComponent = this.state.AddAddressComponent.concat(DvrAddressExpando);
  //   const compAdrCount = this.state.AddNewAdrCount+1;
  //   sessionStorage.setItem('AddAdrCount',compAdrCount);
  //   this.setState({ AddAddressComponent });
  //   this.setState({ AddNewAdrCount:compAdrCount});
  // }
  render() {
    const {classes, name} = this.props
    console.log(this.state.AddNewComponentCount);
    var NewPaymentComponent = '';
    //var NewAddressComponent = '';
    console.log('session ',sessionStorage.getItem('Compcnt'));
    NewPaymentComponent = this.state.AddPaymentComponent.map((Element, index) => {
      return <Element key={ index } index={ index } />
    });
   
    // NewAddressComponent = this.state.AddAddressComponent.map((Element, index) => {
    //   return <Element key={ index } index={ index } />
    // });
   
    //     return <Element key={ index } index={ index } />
    //   });
    // if(sessionStorage.getItem('Compcnt') > 0){
    //   for(let i = 1; i <= sessionStorage.getItem('Compcnt') ; i++) {
    //      NewPaymentComponent =  this.state.AddPaymentComponent.concat(DvrPaymentExpando).map((Element, index) => {
    //       <Element key={ index } index={ index } />
    //     });
    //     return NewPaymentComponent;
    //   }
    // }
    // else{
    //   NewPaymentComponent = this.state.AddPaymentComponent.map((Element, index) => {
    //     return <Element key={ index } index={ index } />
    //   });

    // }
    
    return (
      <React.Fragment>
        <Typography variant="display1" gutterBottom>Payment methods</Typography>
        <DvrPaymentExpando />
        {NewPaymentComponent} <br /> 
        <Button variant="contained"  name="carddetails" onClick={this.AddPaymentCardItem} color="primary" disableRipple className={classNames(classes.margin, classes.bootstrapRoot)}>
        Add More
      </Button>
        <br /> 
        {/* <br />

        <Typography variant="display1" gutterBottom>Addresses</Typography>
        <DvrAddressExpando />
        {NewAddressComponent} <br /> 
        <Button variant="contained"  name="addressDetails"  onClick={this.AddAddressItem} color="primary" disableRipple className={classNames(classes.margin, classes.bootstrapRoot)}>
        Add More
      </Button> */}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    name: ''
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DvrAccount))
