import {
  DVR_SELECT_CAMERA,
  CREATE_SESSION_REQUEST,
  CREATE_SESSION_SUCCESS,
  JOIN_SESSION_REQUEST,
  JOIN_SESSION_SUCCESS
} from '../actions/types'

const initialState = {
  pitch: 0,
  yaw: 0,
  hfov: 100,
  sessionId: null,
  users: {}
}

function collaborate(state = initialState, action) {
  let newState
  switch (action.type) {
    case CREATE_SESSION_SUCCESS:
      newState = {...state, sessionId: action.sessionId}
      return newState
    case JOIN_SESSION_SUCCESS:
      newState = {...state, sessionId: action.session.sessionId}
      return newState
    case DVR_SELECT_CAMERA:
      // Force HFOV back to 100 when the camera changes
      newState = {...state, hfov: 100}
      return newState
    case 'CAMERA_POSITION':
      newState = {...state, pitch: action.pitch, yaw: action.yaw, hfov: action.hfov}
      return newState
      /*
    case 'CAMERA_UPDATE':
      newState = {...state, users: {...state.users, [action.user]: {...action}}}
      return newState
      */
  }
  return state
}

export default collaborate
