import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

const styles = {
  root: {
    width: '100%',
    overflowX: 'auto',
  },
}

const tasks = [
  {taskId: '1', status: 'unclaimed', project: 'Reggiano Estates', task: 'Convert DWG to CAD'},
  {taskId: '2', status: 'unclaimed', project: 'Reggiano Estates', task: 'Apply textures to model'},
  {taskId: '3', status: 'unclaimed', project: 'Reggiano Estates', task: 'Create furniture model'},
  {taskId: '3', status: 'unclaimed', project: 'Reggiano Estates', task: 'Create seamless texture'},
  {taskId: '4', status: 'feedback', project: 'Reggiano Estates', task: 'Convert DWG to CAD'},
]

class DvrTaskList extends React.Component {
  constructor(props) {
    super(props)
    this.classes = props
  }

  render() {
    const {classes} = this.classes
    return (
      <React.Fragment>
      <Typography variant="display1" gutterBottom>Tasks</Typography>
      <Paper className={classes.root}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Project</TableCell>
              <TableCell>Task</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map(task => {
              return (
                <TableRow key={task.projectId}>
                  <TableCell>{task.status}</TableCell>
                  <TableCell>{task.project}</TableCell>
                  <TableCell>{task.task}</TableCell>
                  <TableCell><Button color="primary">Claim</Button></TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Paper>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(DvrTaskList)
