import React from 'react'
import ReactDOM from 'react-dom'
import {withStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import {Formik} from 'formik'
import * as Yup from 'yup'

const styles = {
}

class JoinSessionDialog extends React.Component {
  constructor(props) {
    super(props)
    this.formik = React.createRef()
    this.classes = props
  }

  setSessionId = sessionId => {
    this.setState({sessionId})
  }

  handleJoin = (values, {setSubmitting}) => {
    this.props.handleJoin(values.sessionId)
  }

  handleCancel = () => {
    this.formik.current && this.formik.current.resetForm()
    this.props.handleClose()
  }

  render() {
    const {classes} = this.classes
    const {
      handleClose,
      open
    } = this.props

    return (
      <Formik
        ref={this.formik}
        initialValues={{ sessionId: '' }}
        validationSchema={Yup.object().shape({  
          sessionId: Yup.string('Enter a session ID')
              .required('Session ID is required'),
        })}
        onSubmit={this.handleJoin}
      >
        {({
          values: {sessionId},
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          }) => (
          <form onSubmit={handleSubmit}>
            <Dialog
              open={this.props.open}
              onClose={this.handleCancel}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Join a design session</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Collaborate with others and share changes in real time by entering
                  their session ID in the field below.
                </DialogContentText>
                <TextField
                  autoFocus
                  id="sessionId"
                  label="sessionID"
                  type="text"
                  value={sessionId}
                  placeholder="XXX-XXX-XXX"
                  helperText={touched.sessionId ? errors.sessionId : ''}
                  error={touched.sessionId && Boolean(errors.sessionId)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCancel} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                  Join
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        )}
      </Formik>
    )
  }
}

export default withStyles(styles)(JoinSessionDialog)
