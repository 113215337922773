import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Drawer from '@material-ui/core/Drawer'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import Divider from '@material-ui/core/Divider'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PeopleIcon from '@material-ui/icons/People'
import SettingsIcon from '@material-ui/icons/Settings'
import ListIcon from '@material-ui/icons/List'
import {Link} from 'react-router-dom'
import Protected from './protected'
import ALYRoles from '../aly-roles'

const drawerWidth = 240

const styles = theme => ({
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing.unit * 7,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing.unit * 9,
      },
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
})

class DvrDrawer extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {classes, open, openDrawer, closeDrawer} = this.props
    return (
      <Drawer
        variant="permanent"
        classes={{paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),}}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={closeDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link to="/home">
            <ListItem button>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </Link>

          <Protected roles={[ALYRoles.Administrator]}>
            <Link to="/tasks">
              <ListItem button>
                <ListItemIcon>
                  <ListIcon />
                </ListItemIcon>
                <ListItemText primary="Tasks" />
              </ListItem>
            </Link>
          </Protected>

          <Protected roles={[ALYRoles.Administrator]}>
            <Link to="/users">
              <ListItem button>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Teams" />
              </ListItem>
            </Link>
          </Protected>

          <Protected roles={[ALYRoles.Administrator]}>
            <Link to="/account">
              <ListItem button>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Account" />
              </ListItem>
            </Link>
          </Protected>
        </List>
        <Divider />
      </Drawer>
    )
  }
}

export default withStyles(styles)(DvrDrawer)
