// Authentication saga
//

import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import authData from '../data/user.json'

function fetchAuth() {
  return authData
}

function* authRequest(action) {
  try {
    const auth = yield call(fetchAuth)
    yield put({type: 'AUTH_RESPONSE', payload: { ...auth }})
  } catch (e) {
    yield put({type: 'AUTH_FAILURE', message: e.message})
  }
}


function* authSaga() {
  console.log('Start auth saga')
  yield takeLatest('AUTH_REQUEST', authRequest)
  console.log('Exit auth saga')
}

export default authSaga
