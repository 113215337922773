import React from 'react'
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import { withRouter } from 'react-router'
import {Button } from '@material-ui/core'
import { Z_FIXED } from 'zlib'
import {Link} from 'react-router-dom'
import Chip from '@material-ui/core/Chip'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import {DVR_SELECT_CAMERA} from '../actions/types'
import {selectOption, createSessionRequest, joinSessionRequest} from '../actions/collaborate'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ShareIcon from '@material-ui/icons/Share'

import {Pannellum as AlyVRPannellum, HotSpot as AlyVRHotSpot} from '../vendor/Pannellum'
// import "./slider_pannellam.css"

const styles = theme => ({
  controller: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    background: 'white',
    paddingLeft: 0,
    paddingRight: 0,
    zIndex: 1000,
  },
  grow: {
    flexGrow: 1
  },
  button: {
    margin: theme.spacing.unit,
  },
    HotspotDuplicte:{
    display:'none'
  },
  VRModeClass:{
    position: 'fixed',
    bottom:'20px',
    left: '20px',
    background:'transparent',
    'box-shadow':'none',
    border: 'none',
    '&:hover':{
      background:'transparent',
      'box-shadow':'none',
      border: 'none',
    }
  },
  hotSpot: {
    '&.pnlm-sprite': {
      backgroundImage: null,
    },
    '& > *': {
      transform: 'translate(-50%, -50%)',
    },
  },
})

class DvrPannellum extends React.Component {
  state = {
    anchorEl: null,
    VRMode:false,
    showControls:true,
    changedURL:'',
  }

  constructor(props){
    super(props)
    this.pannellum = React.createRef()
  }

  handleHotSpotClick = (evt, hotSpotName) => {
    this.props.selectCamera(this.props.sessionId, hotSpotName)
  }

  handleMovement = (movement) => {
    const {pitch, yaw, hfov} = movement
    this.props.handleUpdateCameraPosition(pitch, yaw, hfov)
  }

  ViewVRMode (e){
    e.preventDefault();
    const VRMode =(!this.state.VRMode)?true:false
    const Controls = (this.state.showControls)?false:true
    this.setState({VRMode:VRMode,showControls:Controls});
  }

  render() {
    /*
    var currentUrl = this.props.location.pathname.split('/');
    var urlFlag = false;
    if(this.props.options.views[currentUrl[3]] &&  !urlFlag){
      if(currentUrl[3] ==  this.props.options.views[currentUrl[3]].name){
        this.props.options.url  = this.props.options.views[currentUrl[3]].url;
         urlFlag = true;
      }
    }
    */
//    this.props.options.url = (this.state.changedURL !=='' && typeof this.state.changedURL !== 'undefined')?this.state.changedURL:this.props.options.url;
    /*
    let urlHash = window.location.hash;
    if(urlHash !== "") {
      let urlPitchandYaw = urlHash.replace('#','').split('/');
      this.props.options.pitch = (typeof urlPitchandYaw[1] !== 'undefined')?urlPitchandYaw[0]:this.props.options.pitch;
      this.props.options.yaw = (typeof urlPitchandYaw[1] !== 'undefined')?urlPitchandYaw[1]:this.props.options.yaw;
    }
    */

    const {classes, mouseZoom, draggable, image, stereo, showControls, multiRes, pitch, yaw, hfov, minPitch, maxPitch, minYaw, maxYaw} = this.props
    const {anchorEl} = this.state
    const open = Boolean(anchorEl)

    /*
    const multiRes = options.multires 
      ? options.multires : {
      path: "/%l/%s%y_%x",
      fallbackPath: "/fallback/%s",
      extension: "jpg",
      tileResolution: 512,
      maxLevel: 3,
      cubeResolution: 1296
    }
    */

    const VRMode = this.state.VRMode
    if (showControls && VRMode) showControls = false

    return ( 
      <React.Fragment>
        {image && multiRes ? 
          <React.Fragment>
            <AlyVRPannellum type='multires' multiRes={multiRes} ref={this.pannellum} yaw={yaw} pitch={pitch} hfov={hfov} minPitch={minPitch} maxPitch={maxPitch} minYaw={minYaw} maxYaw={maxYaw} mouseZoom={!VRMode && mouseZoom} showControls={showControls} orientationOnByDefault={VRMode} height="100%"  width={VRMode ? "50%" : "100%"} autoLoad draggable={!VRMode && draggable} image={image + (stereo ? '_L' : '')} maxHfov={100} sceneFadeDuration={1000} hotspotDebug={false} onMovement={this.handleMovement}>
              {this.props.hotSpots.map(hs => 
                <AlyVRHotSpot
                  className={classes.hotSpot}
                  id={hs.name}
                  key={hs.name}
                  type="custom"
                  pitch={(typeof hs.pitch === 'undefined' ||  hs.pitch ===null ?10:hs.pitch)}
                  yaw={(typeof hs.yaw === 'undefined' ||  hs.yaw ===null ?180:hs.yaw)}  
                  roll={hs.roll}
                  text={hs.name}
                >
                  <Chip label={hs.name} onClick={evt => this.handleHotSpotClick(evt, hs.name)}/>
                </AlyVRHotSpot>
              )}
            </AlyVRPannellum>
            {VRMode ? 
              <Pannellum type='multires' multiRes={multiRes} yaw={yaw}  pitch={pitch} hfov={hfov} minPitch={minPitch} maxPitch={maxPitch} minYaw={minYaw} maxYaw={maxYaw} mouseZoom={false} showControls={false} sceneFadeDuration={1000}  orientationOnByDefault={true} height="100%"  width="50%" autoLoad draggable={false} image={image + (stereo ? '_R' : '')} maxHfov={100} sceneFadeDuration={1000} onMovement={this.handleMovement}/>
            : null
            }
          </React.Fragment>
        : null}
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(DvrPannellum)
