import {
  CREATE_SESSION_REQUEST,
  CREATE_SESSION_SUCCESS,
  JOIN_SESSION_REQUEST,
  JOIN_SESSION_SUCCESS,
  DVR_SELECT_OPTION
} from './types'

export function createSessionRequest() {
  return {
    type: CREATE_SESSION_REQUEST
  }
}

export function createSessionSuccess(sessionId) {
  return {
    type: CREATE_SESSION_SUCCESS,
    sessionId
  }
}

export function joinSessionRequest(sessionId) {
  return {
    type: JOIN_SESSION_REQUEST,
    sessionId
  }
}

export function joinSessionSuccess(session) {
  return {
    type: JOIN_SESSION_SUCCESS,
    session
  }
}

export function selectOption(sessionId, name, value) {
  return {
    type: DVR_SELECT_OPTION,
    sessionId,
    name,
    value
  }
}
