import React from 'react'
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/core/styles'
import {Link} from 'react-router-dom'
import DvrProjectList from '../components/projectlist'
import Button from '@material-ui/core/Button'
import Protected from '../components/protected'
import ALYRoles from '../aly-roles'

const styles = {
  flex: {
    display: 'flex',
    flexFlow: 'row'
  },
  buttonBar: {
    paddingTop: '1em'
  }
}

class DvrHome extends React.Component {
  componentWillMount() {
    this.props.fetchConfig()
  }

  render() {
    const {classes, projects} = this.props
    return (
      <React.Fragment>
        <DvrProjectList projects={projects} />
        <Protected roles={[ALYRoles.Administrator]}>
          <div className={classes.buttonBar}>
            <Button variant="contained" color="primary" component={Link} to="/project/new">Create new project</Button>
          </div>
        </Protected>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  console.log('Map home state')
  return {
    projects: (state.options.config && state.options.config.projects) ? state.options.config.projects : []
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchConfig: () => dispatch({type: 'FETCH_CONFIG'})
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DvrHome))
