export const OPEN_DRAWER = 'OPEN_DRAWER'
export const CLOSE_DRAWER = 'CLOSE_DRAWER'
export const JOIN_SESSION_REQUEST = 'JOIN_SESSION_REQUEST'
export const JOIN_SESSION_SUCCESS = 'JOIN_SESSION_SUCCESS'
export const CREATE_SESSION_REQUEST = 'CREATE_SESSION_REQUEST'
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS'
export const DVR_SELECT_OPTION = 'DVR_SELECT_OPTION'
export const DVR_SELECT_CAMERA = 'DVR_SELECT_CAMERA'
export const DVR_USER_SELECT_OPTION = 'DVR_USER_SELECT_OPTION'
export const HIDE_APPBAR = 'HIDE_APPBAR'
export const SHOW_APPBAR = 'SHOW_APPBAR'

export default {
  OPEN_DRAWER,
  CLOSE_DRAWER,
  HIDE_APPBAR,
  SHOW_APPBAR,
  DVR_SELECT_OPTION,
  DVR_SELECT_CAMERA,
  DVR_USER_SELECT_OPTION,
  JOIN_SESSION_REQUEST,
  JOIN_SESSION_SUCCESS,
  CREATE_SESSION_REQUEST,
  CREATE_SESSION_SUCCESS
}
