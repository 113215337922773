import React from 'react'
import { Redirect } from 'react-router'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions'
import Typography from '@material-ui/core/Typography'
import NativeSelect from '@material-ui/core/NativeSelect'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Divider from '@material-ui/core/Divider'
import CardMedia from '@material-ui/core/CardMedia'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import classNames from 'classnames'
import {Formik} from 'formik'
import * as Yup from 'yup'

const styles = theme => ({
  root: {
    width: '100%',
  },
  card: {
    maxWidth:495,
    height:'auto',
    margin: '0 auto',
    padding:'20px',
    background: '#f0f7fd',
    border:'1px solid #ddd',
    'box-shadow':'0 1px 2px #000',
    'border-radius':"6px", 
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  formControl: {
    padding: '0px 15px',
  },
  cardvalidcalss: {
    color:'#008000'
  },
  cardInvalidcalss:{
    color:'#FF0000'
  },
  cardImages:{
    
  },
  cardformclass:{
    width:'100%',
  },
  textfields:{
    margin:'0 0 15px',
    'min-width':'50%',
  },
  backslash_div:{
    position: 'relative',
    '&:after':{
      content:'"/"',
      position:'absolute',
      right:'-4px',
      top:'50%',
      'font-size': '20px',
      transform: 'translateY(-50%)',
      '-webkit-transform': 'translateY(-50%)',
      '-moz-transform': 'translateY(-50%)',
      '-ms-transform': 'translateY(-50%)',
    } 
  },
  selectlabel:{
    display: 'inline-flex',
    position: 'relative',
  },
  cardArea:{
    
  },
  selectfieldExp:{
    'min-width': '40px'
  },
  addresSection:{
    maxWidth:495,
    height:'auto',
    margin: '50px auto',
    background: '#ffffff',
  },
  inputExplabel:{
    position:'absolute',
    bottom:'18px',
    left: '15px',
    'font-size':'14px',
    'font-weight':'700'
  },
  inputfields:{

  },
  media:{
    width: '80px',
    height: '40px',
    display: 'inline-block',
    'background-size': 'contain'
  },
  creditcardImage:{
    'text-align':'right',
    'margin-top': '15px'
  },
  selectCountryState:{
    'min-width': '50%',
  },
  selectState:{
    'min-width': '50%',
  },
  selecErrorText:{
    color:'#FF0000'
  }
});

class DvrPaymentExpando extends React.Component {
  constructor(props) {
    super(props)
    this.ValidateCreditCardNumber  = this.ValidateCreditCardNumber.bind(this);
    this.ValidateCreditCardExpirary= this.ValidateCreditCardExpirary.bind(this);
    //console.log('super=='+JSON.stringify(props));
    this.state = {
      cardValidationErrors : false,
      redirect:false,
      cardValue:'',
      cardMonthDate: '',
      selectMonth:'',
      selectYear:'',
      selecCountryName:'',
      selecCountryStateName:'',
      stateList:''
    }
  }
  
  ValidateCreditCardNumber() {
    var msg = '';
    const ccNum = document.getElementById("cardNumber").value;
    let visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    //let mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
    let amexpRegEx = /^(?:3[47][0-9]{13})$/;
    let discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    let isValid = false;
    if (visaRegEx.test(ccNum)) {
      isValid = true;
    } else if(amexpRegEx.test(ccNum)) {
      isValid = true;
    } else if(discovRegEx.test(ccNum)) {
      isValid = true;
    }
    if(isValid) {
      msg = null
    } else {
      msg = 'card not valid'
  
    }

   // console.log(msg)
    return msg;
  }
  ValidateCreditCardExpirary() {
        var expirarMonth= document.getElementById("expiryMonth").value;
        var expiraryYear = document.getElementById("expiryYear").value;
        var cardresult    = false;
        var expmsg = '';
          cardresult = (parseInt(expiraryYear) >=  new Date().getFullYear() || ( parseInt(expiraryYear)== new Date().getFullYear() && parseInt(expirarMonth) >= new Date().getMonth() + 1))
        if(cardresult) {
          expmsg = null
        } else {
          expmsg = 'card Expiry date is not valid'
      
        }
        //console.log(expmsg)
     //   return this.setState({'cardMonthDate':expmsg});
        return expmsg;
  }
  render() {
    const {classes, name} = this.props
    if (this.state.redirect) {
      return <Redirect to='/home'/>;
    }
    const mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
    const expiraryRegEx = /[\d]{2} \/ [\d]{4}/
    const zipcodeRegex =  /^[0-9]{5}(?:-[0-9]{4})?$/
   
    const month = [];
    for (var i = new Date().getMonth()+1; i <= 12; i++){
      if(i < 10){
        i = "0"+i
      }
       month.push({'name':i,'value':i})
    }
    const year = [];
    for (var i = new Date().getFullYear(); i < new Date().getFullYear()+15; i++){
      year.push({'name':i,'value':i})
    }
    const countryList = [
      {
          "name": "India",
          "code": "IN"
      },
      {
          "name": "UAE",
          "code": "AE"
      },
      {
          "name": "USA",
          "code": "US"
      },
  ];
  const stateList = {
    "state":[
      {"value":"Alabama - AL","name":"AL"},
      {"value":"Alaska - AK","name":"AK"},
      {"value":"Arkansas - AR","name":"AR"},
      {"value":"California - CA","name":"CA"},
      {"value":"Colorado - CO","name":"CO"},
      {"value":"Delaware - DE","name":"DE"},
    ],
    // "UAE":[

            
    //   {"value":"Abu Dhabi–AUH","name":"AUH"},
    //   {"value":"Ajman – AJM","name":"AJM"},
    //   {"value":"Sharjah – SHJ","name":"SHJ"},
    //   {"value":"Dubai – DXB","name":"DXB"},
    //   {"value":"Fujairah – FUJ","name":"FUJ"},
    //   {"value":"Ras Al Khaimah – RAK","name":"RAK"},
    //   {"value":"Umm Al Quwain – UAQ","name":"UAQ"},
    // ],
    // "India":[
    //   {"value":"Andhra Pradesh - AD","name":"AD"},
    //    {"value":"Arunachal Pradesh - AR","name":"AR"},
    //    {"value":"Assam - AS","name":"AS"},
    //    {"value":"Bihar - BR","name":"BR"},
    //    {"value":"Chhattisgarh - CG","name":"CG"},
    //    {"value":"Goa - GA","name":"GA"},
    //    {"value":"Gujarat - GJ","name":"GJ"},
    //    {"value":"Haryana - HR","name":"HR"},
    //    {"value":"TamilNadu - TN","name":"TN"},
    // ]
    }
    return (
      <React.Fragment>
				<ExpansionPanel>
					<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
						<div className={classes.column}>
							<Typography className={classes.heading}>Card Details</Typography>
						</div>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails className={classes.details}>
        <Formik
            initialValues={{ firstName: '', lastName: '', cardNumber:'',expiryYear:'',expiryMonth:'',cvc:'',street:'',city:'',zipcode:'',state:'',country:''}}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
               alert(JSON.stringify(values, null, 2));
               this.setState({cardValue : values , redirect : true});
              setSubmitting(false);
              }, 500);
            }}
            validationSchema={Yup.object().shape({  
            firstName: Yup.string('Enter a FirstName')
                .required('First name is required'),
            lastName: Yup.string('Enter a Lastname')
                .required('Last name is required'),
            cardNumber: Yup.string().required('Add Mastercard details').matches(mastercardRegEx, this.ValidateCreditCardNumber),
            expiryYear: Yup.string().required('expiry card requied'),
            expiryMonth:Yup.string().required('card expiry Month'),
            // expiryYear:Yup.string().required('card expiry Year'),
            cvc: Yup.string('Enter a Card CVV').min(3).max(4).required('Enter Card CVV'),
            street: Yup.string('Street Name')
            .required('Street name is required'),
            city: Yup.string('City Name')
                .required('City name is required'),
            zipcode: Yup.string().matches(zipcodeRegex,'Zipcode not valid')
                .required('ZipCode is required'),
            state: Yup.string('State Name')
                .required('State name is required'),
            country: Yup.string('Country Name ')
                .required('Country name is required'),
      
            })}
        >
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            handleClose,
            handleOpen
          } = props;
        //console.log('formprops'+JSON.stringify(props));
        const keydown =  (name, e) => {
          var inputLength = e.target.value.length;
          var inputVal = e.target.value;
          //console.log('inputVal = '+inputVal + '=inputLength='+inputLength);
          if (e.keyCode != 8){
            if(!inputVal.includes('/')){
                if(inputLength === 2){
                  var thisVal = e.target.value;
                  thisVal += '/';
                  e.target.value  = thisVal;                  
                  //console.log('inpu val = ',thisVal)
             }
           }
          }
        }
        const MontChange = (name,e)=>{
          this.setState(() => {
            return {
              selectMonth: event.target.value
            }
          })
        }
        const YearChange = (name,e)=>{
          this.setState(() => {
            return {
              selectYear: event.target.value
            }
          })
        }
    
        // const changeCountry = (name,e)=>{
        //   const  optionList =  stateList.e.target.value.map(selecCountryStateName => (
        //     <option value={selecCountryStateName.value} key={selecCountryStateName.name} >
        //         {selecCountryStateName.value}
        //     </option>
        //     ))
        //     this.setState({stateList:optionList})
        //     console.log(this.state.stateList)

        // }
        return ( 
           <form className={classNames(classes.container, classes.cardformclass)} noValidate autoComplete="off"  method="post" onSubmit={handleSubmit}>
        <div className={classes.card}>
            <div className={classes.cardArea}>
            <FormControl className={classNames(classes.formControl, classes.textfields)}>
                    <TextField className={classes.inputfields}
                    id="cardNumber"
                    placeholder="Enter your Card Number"
                    value={values.cardNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.cardNumber ? errors.cardNumber: ''}
                    error={touched.cardNumber && Boolean(errors.cardNumber)}
                    className={classes.textField}
                  />
              </FormControl>
              <FormControl className={classNames(classes.formControl, classes.textfields)}>
                    <TextField className={classes.inputfields}
                      id="cvc"
                      placeholder="cvv/cvc"
                      value={values.cvc}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.cvc ? errors.cvc : ''}
                      error={touched.cvc && Boolean(errors.cvc)}
                      className={classes.textField}
                  />
                </FormControl>
              <FormControl className={classNames(classes.formControl, classes.textfields)} >
                <TextField className={classes.inputfields}
                    id="firstName"
                    placeholder="Enter your Full Name"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.firstName ? errors.firstName : ''}
                    error={touched.firstName && Boolean(errors.firstName)}
                    className={classes.textField}
                  />
                </FormControl>
              
                {/* <FormControl className={classNames(classes.formControl, classes.textfields)}>
                  <TextField className={classes.inputfields}
                    id="lastName"
                    placeholder="Enter your Last Name"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.lastName ? errors.lastName : ''}
                    error={touched.lastName && Boolean(errors.lastName)}
                    className={classes.textField}
                  />
            
                </FormControl> */}
                <div className={classes.selectlabel}>
                  <FormControl className={classNames(classes.formControl, classes.textfields, classes.backslash_div)}>
                  <Select className={classNames(classes.selectfields)}
                      onClose={handleClose}
                      onOpen={handleOpen}
                      value={values.expiryMonth}
                      placeholder="MM/YY"
                      onBlur={handleBlur}
                      error={touched.expiryMonth && Boolean(errors.expiryMonth)}
                      onChange={handleChange}
                      inputProps={{
                        name: 'expiryMonth',
                        id: 'expiryMonth',
                        className:classes.selectfieldExp,
                      }}
                    >
                    {month.map(selectMonth => (
                      <MenuItem value={selectMonth.value} key={selectMonth.name} >
                        {selectMonth.value}
                      </MenuItem>
                    ))}
                    </Select>
                  </FormControl>
                  
                  <FormControl className={classNames(classes.formControl, classes.textfields)}>
                  <Select className={classNames(classes.selectfields)}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        value={values.expiryYear}
                        placeholder="MM/YY"
                        error={touched.expiryYear && Boolean(errors.expiryYear)}
                        onChange={handleChange}
                        // onBlur={this.ValidateCreditCardExpirary}
                        inputProps={{
                          name: 'expiryYear',
                          id: 'expiryYear',
                          className:classes.selectfieldExp,
                        }}
                      >
                      {year.map(selectYear => (
                        <MenuItem value={selectYear.value} key={selectYear.name} >
                          {selectYear.value}
                        </MenuItem>
                      ))}
                    </Select>
                  <FormHelperText id="component-error-text" className={(this.state.cardMonthDate === "card valid")?classes.cardvalidcalss:classes.cardInvalidcalss} >{touched.expiryYear ? this.state.cardMonthDate : ''}</FormHelperText>
                  </FormControl>
                    <InputLabel className={classes.inputExplabel}>Exp. Date</InputLabel>
                  </div>

                  <div className={classes.creditcardImage}>
                    <CardMedia className={classNames(classes.media, classes.media)} image="/media/images/mastercard.jpg" title="Master card" />
                    <CardMedia className={classNames(classes.media, classes.media)} image="/media/images/visa.jpg"  title="Visa card" />
                    <CardMedia className={classNames(classes.media, classes.media)} image="/media/images/amex.jpg"  title="Amex card" />
                  </div>
            </div>
            </div>
            <div className={classes.addresSection}>
            <FormControl className={classNames(classes.formControl, classes.textfields)}>
                    <TextField className={classes.inputfields}
                      id="street"
                      placeholder="Street name"
                      value={values.street}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.street ? errors.street : ''}
                      error={touched.street && Boolean(errors.street)}
                      className={classes.textField}
                    />
              
                  </FormControl>
                  <FormControl className={classNames(classes.formControl, classes.textfields)}>
                      <TextField className={classes.inputfields}
                      id="city"
                      placeholder="City"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.city ? errors.city : ''}
                      error={touched.city && Boolean(errors.city)}
                      className={classes.textField}
                    />
                  </FormControl>
                  <FormControl className={classNames(classes.formControl, classes.textfields,classes.selectCountryState)}>              
                    <NativeSelect className={classes.selectfields}
                        value={values.country}
                        className={classes.selectEmpty}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={classes.textField}
                        error={touched.country && Boolean(errors.country)}
                        name="country"
                        id="country"
                      >
                      <option vlaue="" disabled>select country</option>
                       {
                         countryList.map(selecCountryName => (
                        <option value={selecCountryName.name} key={selecCountryName.code} >
                          {selecCountryName.name}
                        </option>
                      ))}
                  </NativeSelect>
                     <FormHelperText className={classes.selecErrorText}>{touched.country ? errors.country : ''}</FormHelperText>
                  </FormControl>
                {/* {(values.country !="")?  */}
                  <FormControl className={classNames(classes.formControl, classes.textfields,classes.selectState)}>              
                    <NativeSelect className={classes.selectfields}
                        value={values.state}
                        id="countryState"
                        className={classes.selectEmpty}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={classes.textField}
                        error={touched.state && Boolean(errors.state)}
                        name="state"
                        id="state"
                      >
                      {
                           stateList.state.map(selecCountryStateName => (
                            <option value={selecCountryStateName.value} key={selecCountryStateName.name} >
                                {selecCountryStateName.value}
                            </option>
                           ))
                        }
              
                         
                  </NativeSelect>
                     <FormHelperText className={classes.selecErrorText}>{touched.state ? errors.state : ''}</FormHelperText>
                  </FormControl>
                    {/* :''} */}
                  <FormControl className={classNames(classes.formControl, classes.textfields)}>
                      <TextField className={classes.inputfields}
                      id="zipcode"
                      placeholder="ZipCode"
                      value={values.zipcode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.zipcode ? errors.zipcode : ''}
                      error={touched.zipcode && Boolean(errors.zipcode)}
                      className={classes.textField}
                    />
                  </FormControl>
                  </div>
            <Divider />

            <ExpansionPanelActions>
                  <Button  size="small" type="button" className="outline" onClick={handleReset}  disabled={!dirty || isSubmitting} > Cancel </Button>
                  <Button size="small" color="primary"type="submit" disabled={isSubmitting}>Save</Button>
                  <Button size="small" color="secondary">Delete</Button>
              
            </ExpansionPanelActions>
          </form>
        );
      }}
    </Formik>
					</ExpansionPanelDetails>
				</ExpansionPanel>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(DvrPaymentExpando)
