import {
  OPEN_DRAWER,
  CLOSE_DRAWER,
  SHOW_APPBAR,
  HIDE_APPBAR,
} from './types'

export function openDrawer() {
  return { type: OPEN_DRAWER }
}

export function closeDrawer() {
  return { type: CLOSE_DRAWER }
}

export function showAppbar() {
  return { type: SHOW_APPBAR }
}

export function hideAppbar() {
  return { type: HIDE_APPBAR }
}
