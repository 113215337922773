import React from 'react'
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import {Formik} from 'formik'
import * as Yup from 'yup'

const styles = theme => ({
  flex: {
    display: 'flex',
    flexFlow: 'row'
  },
  hflex: {
    position: 'absolute',
    display: 'flex',
    flexFlow: 'column',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
  },
  grow: {
    flexGrow: 1
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
  },
})

const validationSchema = Yup.object({
  name: Yup.string('Enter a name')
    .required('Project name is required'),
  description: Yup.string('Enter a description')
    .required('Project description is required')
})

const handleChange = (name, event) => {
  console.log('EV', name, event)
}

class DvrProject extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: ''
    }
  }

  render() {
    const {classes} = this.props
    return (
      <React.Fragment>
        <Typography variant="display1" gutterBottom>Create Project</Typography>
        <Paper>
          <Formik 
            validationSchema={validationSchema}
            initialValues={{name: "", description: ""}}
            render={props => {

            const {
              values: {name, description},
              errors,
              touched,
              handleChange,
              isValid,
              setFieldTouched
            } = props

            const change = (name, e) => {
              e.persist()
              handleChange(e)
              setFieldTouched(name, true, false)
            }

            const blur = (name, e) => {
              setFieldTouched(name, true, true)
            }

            return <form className={classes.container} noValidate autoComplete="off">
              <FormControl className={classes.formControl}>
                <TextField
                  id="name"
                  label="Name"
                  name="name"
                  helperText={touched.name ? errors.name : ''}
                  error={touched.name && Boolean(errors.name)}
                  className={classes.textField}
                  value={name}
                  onChange={change.bind(null, "name")}
                  onBlur={blur.bind(null, "name")}
                  margin="normal"
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <TextField
                  id="description"
                  label="Description"
                  name="description"
                  helperText={touched.description ? errors.description : ''}
                  error={touched.description && Boolean(errors.description)}
                  multiline
                  fullWidth
                  className={classes.textField}
                  value={description}
                  onChange={change.bind(null, "description")}
                  onBlur={blur.bind(null, "description")}
                  margin="normal"
                />
              </FormControl>
            </form>
          }
          } />
        </Paper>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DvrProject))
