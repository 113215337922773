import {
  HIDE_APPBAR, 
  SHOW_APPBAR, 
  OPEN_DRAWER, 
  CLOSE_DRAWER
} from '../actions/types'

const initialState = {
  open: false,
  visible: true,
}

function appbar(state = initialState, action) {
  switch (action.type) {
    case OPEN_DRAWER:
      return {
        ...state,
        open: true
      }
    case CLOSE_DRAWER:
      return {
        ...state,
        open: false
      }
    case SHOW_APPBAR:
      console.log('SHOW APPBAR')
      return {
        ...state,
        visible: true
      }
    case HIDE_APPBAR:
      console.log('HIDE APPBAR')
      return {
        ...state,
        visible: false
      }
  }
  return state
}

export default appbar
