import React from 'react'
import createHistory from 'history/createBrowserHistory'
import {connectRouter, routerMiddleware} from 'connected-react-router'
import {ConnectedRouter} from 'connected-react-router'
import {createStore, combineReducers, applyMiddleware, compose} from 'redux'
import {createDevTools} from 'redux-devtools'
import 'regenerator-runtime/runtime'
import createSagaMiddleware from 'redux-saga'
import LogMonitor from 'redux-devtools-log-monitor'
import DockMonitor from 'redux-devtools-dock-monitor'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import rootSaga from './sagas'

const DevTools = createDevTools(
	<DockMonitor toggleVisibilityKey="ctrl-h" changePositionKey="ctrl-q" defaultIsVisible={false}> 
		<LogMonitor theme="tomorrow" />
	</DockMonitor>
)

export const history = createHistory()

const initialState = {
}

const sagaMiddleware = createSagaMiddleware()

export const store = createStore(
  connectRouter(history)(rootReducer),
  initialState,
  compose(
    applyMiddleware(
      routerMiddleware(history),
      thunk,
      sagaMiddleware
    ),
    DevTools.instrument()
  )
)

sagaMiddleware.run(rootSaga)
