import React from 'react'
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/core/styles'
import DvrTaskList from '../components/tasklist'
import Button from '@material-ui/core/Button'

const styles = {
  flex: {
    display: 'flex',
    flexFlow: 'row'
  },
}

class DvrHome extends React.Component {
  render() {
    const {classes} = this.props
    return (
      <React.Fragment>
        <DvrTaskList />
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DvrHome))
