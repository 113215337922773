import React from 'react'
import ReactDOM from 'react-dom'
import DesignVr from './containers/designvr.jsx'
import {ConnectedRouter} from 'connected-react-router'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import {Provider} from 'react-redux';
import {createMuiTheme} from '@material-ui/core/styles'
import {store, history} from './store'
import ReactGA from 'react-ga'

import {setMoquiBase} from 'bf-moqui-frontend-react'

const hostnameSuffix = window.location.hostname.match(/(?:(?:www|dev)\.)?(.*)/)[1]
const moquiBase = 'https://moqui.' + hostnameSuffix + '/rest/s1/'

ReactGA.initialize('UA-139411484-1')
ReactGA.pageview(window.location.pathname + window.location.search)

setMoquiBase(moquiBase)

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#47474f',
    }
  },
})

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MuiThemeProvider theme={theme}>
        <DesignVr />
      </MuiThemeProvider>
    </ConnectedRouter>
  </Provider>
)

ReactDOM.render(
    <App />,
    document.getElementById('app')
)
