import React from 'react'
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const styles = {
  flex: {
    display: 'flex',
    flexFlow: 'row'
  },
  hflex: {
    position: 'absolute',
    display: 'flex',
    flexFlow: 'column',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
  },
  grow: {
    flexGrow: 1
  }
}

class RecoverPassword extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {classes} = this.props
    return (
      <React.Fragment>
      <Paper>
        <h1>Recover password</h1>
      </Paper>
      </React.Fragment>
    )
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }
}

function mapStateToProps(state) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(RecoverPassword))
