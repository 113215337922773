import {combineReducers} from 'redux'
import appbar from './appbar'
import options from './options'
import collaborate from './collaborate'
import auth from './auth'

export default combineReducers({
  auth,
  appbar,
  collaborate,
  options
})
