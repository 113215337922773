import React from 'react'
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/core/styles'
import {Link} from 'react-router-dom'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import {DVR_SELECT_CAMERA} from '../actions/types'
import {userSelectOption} from '../actions/collaborate'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import MaskedInput from 'react-text-mask'
import {Formik} from 'formik'
import * as Yup from 'yup'

const validationSchema = Yup.object({
  sessionId: Yup.string('Enter your sessionId')
    .transform((value, orig) => {
      return this.isType(value) && value !== null ? value.toUpperCase() : value
    })
    .required('Session ID is required')
})

const styles = theme => ({
  controller: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    background: 'white',
    paddingLeft: 0,
    paddingRight: 0,
    zIndex: 1000,
  },
  button: {
    margin: theme.spacing.unit,
  },
  flex: {
    flexGrow: 1
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[A-Z]/, /[A-Z]/, /[A-Z]/, '-', /[A-Z]/, /[A-Z]/, /[A-Z]/, '-', /[A-Z]/, /[A-Z]/, /[A-Z]/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

class DvrConnectionPanel extends React.Component {
  handleChange = (name, event) => {
    console.log('EV', name, event)
  }

  render() {
    const {classes} = this.props
    return (
      <React.Fragment>
        <Toolbar className={classes.controller}>
          <Link to="/home">
            <IconButton className={classes.button} aria-label="Back">
              <ArrowBackIcon />
            </IconButton>
          </Link>
       </Toolbar>
       <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
       >
        <Grid item xs={3} style={{ minWidth: '300px' }}>
         <Card>
           <CardContent>
            <Typography>Please enter your session ID or press the button to start a new session.</Typography>

            <Formik 
              validationSchema={validationSchema}
              initialValues={{sessionId: ""}}
              render={props => {
              const {
                values: {sessionId},
                errors,
                touched,
                isValid,
                handleChange,
                setFieldTouched
              } = props

              const change = (name, e) => {
                console.log('E', e)
                e.persist()
                handleChange(e)
                setFieldTouched(name, true, false)
              }

              const blur = (name, e) => {
                setFieldTouched(name, true, true)
              }

              // helperText={touched.name ? errors.name : ''}
              return <form className={classes.container} noValidate autoComplete="off">
                <FormControl className={classes.formControl}>
                  <Input
                    autoFocus={true}
                    id="sessionId"
                    name="sessionId"
                    label="Session ID"
                    error={touched.name && Boolean(errors.name)}
                    className={classes.textField}
                    placeholder="XXX-XXX-XXX"
                    inputComponent={TextMaskCustom}
                    value={sessionId}
                    onChange={change.bind(null, "sessionId")}
                    onBlur={blur.bind(null, "sessionId")}
                  />
                </FormControl>
                <Typography>- or -</Typography>
                <Button variant="contained" color="primary" component="span" className={classes.button} onClick={this.props.handleChange}>Start new session</Button>
              </form>
              }} />
           </CardContent>
         </Card>
        </Grid>
       </Grid>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DvrConnectionPanel))
