import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import PersonIcon from '@material-ui/icons/Person'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import DvrDrawer from './drawer'
import {Link} from 'react-router-dom'

const drawerWidth = 240
const styles = theme => ({
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  flex: {
    flexGrow: 1
  },
  loginIcon: {
    minWidth: 25,
    paddingLeft: 0,
    paddingRight: 0,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 6,
  },
  menuButtonHidden: {
    display: 'none',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
})

class DvrAppBar extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {classes, visible, drawerVisible, open, openDrawer, closeDrawer} = this.props
    return (
      <React.Fragment>
        {visible ?
        <AppBar position="absolute" className={classNames(classes.appBar, open && classes.appBarShift)}>
          <Toolbar disableGutters={!open} className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={openDrawer}
              className={classNames(
                classes.menuButton,
                open && classes.menuButtonHidden,
              )}
            >
              <MenuIcon />
            </IconButton>
            <img src="https://s3.amazonaws.com/dvr-panobuilder-assets/aly_logo_small.png" />
            <div className={classes.flex}></div>
          </Toolbar>
        </AppBar>
        : null}
        {visible ?
        <DvrDrawer open={open} openDrawer={openDrawer} closeDrawer={closeDrawer} />
        : null}
      </React.Fragment> 
    )
  }
}

DvrAppBar.defaultProps = {
  visible: true,
  drawerVisible: true
}

export default withStyles(styles)(DvrAppBar)
