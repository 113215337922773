import { fork, call, put, take, takeEvery, takeLatest } from 'redux-saga/effects'
import io from 'socket.io-client'
import { eventChannel } from 'redux-saga'
import {select} from 'redux-saga/effects'
import {
  joinSessionRequest,
  joinSessionSuccess,
  createSessionRequest,
  createSessionSuccess,
  selectOption
} from '../actions/collaborate'
import {
  JOIN_SESSION_REQUEST,
  JOIN_SESSION_SUCCESS,
  CREATE_SESSION_REQUEST,
  CREATE_SESSION_SUCCESS,
  DVR_SELECT_OPTION,
  DVR_SELECT_CAMERA
} from '../actions/types'
import {history} from '../store'
import ReactGA from 'react-ga'

// Connect socket to React
const hostnameSuffix = window.location.hostname.match(/(?:(?:www|dev)\.)?(.*)/)[1]
const socket = io('//ws.' + hostnameSuffix)

function subscribe(socket) {
  return eventChannel(emit => {
    socket.on('optionset', msg => {
      emit({type: 'REMOTE_OPTION_SET', name: msg.name, value: msg.value})
    })

    socket.on('cameraset', msg => {
      emit({type: 'REMOTE_CAMERA_SET', name: msg.name, value: msg.value})
    })
    
    socket.on('CAMERA_UPDATE', msg => {
      emit({type: 'CAMERA_UPDATE', ...msg})
    })

    return () => {
      console.log('Unsubscribe requested')
    }
  })
}

function* recoverStoredSession() {
  console.log('RECOVER SESSION')
  const storedSession = sessionStorage.getItem('alySessionId')
  if (storedSession) {
    yield put({type: 'JOIN_SESSION_REQUEST', sessionId: storedSession})
  }
}

function* listen() {
  const channel = yield call(subscribe, socket)
  while (true) {
    let action = yield take(channel)
    console.log('RECEIVED', action)
    yield put(action)
  }
}

function translateName(name) {
  if (name.startsWith('.')) {
    return name.split(':')[1]
  } else {
    return name
  }
}

function* setOption(action) {
  ReactGA.pageview(window.location.pathname + '/option/' + action.name + '-' + translateName(action.value))
  socket.emit('optionset', {
    sessionId: action.sessionId,
    name: action.name,
    value: action.value
  })
}

function* setCamera(action) {
  ReactGA.pageview(window.location.pathname + '/camera/' + action.value)
  socket.emit('cameraset', {
    sessionId: action.sessionId,
    name: action.name,
    value: action.value
  })
}

function* createSession() {
  try {
    const state = yield select((state) => {
      return {options: state.options}
    })

    const sessionId = yield call(function() {
      return new Promise(function(resolve, reject) {
        socket.emit(CREATE_SESSION_REQUEST, {
          selected: state.options.selected, 
          available: state.options.available, 
          camera: state.options.camera, 
          cameras: state.options.cameras, 
          modelId: state.options.modelId
        }, (msg) => {
          resolve(msg.sessionId)
        })
      })
    })
    sessionStorage.setItem('alySessionId', sessionId)
    yield put(createSessionSuccess(sessionId))
  } catch (e) {
    yield put({type: 'CREATE_SESSION_FAILURE', message: e.message})
  }
}

function* joinSession(args) {
  try {
    console.log('JOIN SESSION REQUEST', args)
    const session = yield call(function() {
      return new Promise(function(resolve, reject) {
        socket.emit(JOIN_SESSION_REQUEST, {sessionId: args.sessionId}, (msg) => {
          console.log('JOIN SESSION REQUEST: msg ', msg)
          resolve(msg)
        })
      })
    })
    console.log('JOIN SESSION STATUS', session)
    if (session.sessionStatus !== 'FAILURE') {
      yield put(joinSessionSuccess(session))
      sessionStorage.setItem('alySessionId', args.sessionId)
      history.push('/project/' + session.modelId)
    } else {
      sessionStorage.removeItem('alySessionId')
    }
  } catch (e) {
    yield put({type: 'JOIN_SESSION_FAILURE', message: e.message})
  }
}

function* cameraPosition(args) {
  const cameraState = yield select((state) => {
    return {
      sessionId: state.collaborate.sessionId, 
      camera: state.options.camera,
      url: state.options.url
    }
  })
  socket.emit('CAMERA_POSITION', {
    pitch: args.pitch,
    yaw: args.yaw,
    ...cameraState
  })
}

function* cameraUpdate(args) {
}

function* collaborateSaga() {
  yield takeLatest(DVR_SELECT_CAMERA, setCamera)
  yield takeLatest(DVR_SELECT_OPTION, setOption)
  yield takeEvery(CREATE_SESSION_REQUEST, createSession)
  yield takeEvery(JOIN_SESSION_REQUEST, joinSession)
  yield takeEvery('CAMERA_POSITION', cameraPosition)
  yield takeEvery('CAMERA_UPDATE', cameraUpdate)
  yield fork(listen)
  yield fork(recoverStoredSession)
}

export default collaborateSaga
