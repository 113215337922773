const initialState = {
  isAuthenticated: false
}

function auth(state = initialState, action) {
  let newState
  switch (action.type) {
    case 'AUTH_RESPONSE':
      newState = {...state, ...action.payload}
      return newState
  }

  // No match. Return existing state.
  return state
}

export default auth
