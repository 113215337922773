import React from 'react'
import {connect} from 'react-redux'
import {Switch, Route, withRouter, Redirect} from 'react-router'
import {withStyles} from '@material-ui/core/styles';
import Front from './front.jsx'
import Home from './home.jsx'
import Users from './users.jsx'
import Tasks from './tasks.jsx'
import CreateProject from './create-project.jsx'
import Project from './project.jsx'
import Account from './account.jsx'
import ProjectMaterials from './project-materials.jsx'
import RecoverPassword from './recover-password.jsx'
import DvrAppBar from '../components/dvrappbar'
import CssBaseline from '@material-ui/core/CssBaseline'
import {openDrawer, closeDrawer} from '../actions/appbar'
import PrivateRoute from '../components/private-route'
import {joinSessionRequest} from '../actions/collaborate'

const styles = theme => ({
  root: {
        display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
  },
  viewer: {
    flexGrow: 1,
    padding: 0,
    height: '100vh',
    overflow: 'auto',
  },
  appBarSpacer: theme.mixins.toolbar,
})

class DesignVr extends React.Component {
  SessionRedirect = args => {
    const sessionId = args.location.pathname.split('/')[2]
    console.log('REDIR', args, sessionId)
    this.props.joinSession(sessionId)
    return null
  }

  render() {
    const {visible, classes, open, openDrawer, closeDrawer} = this.props
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={visible ? classes.root : null}> 
          <DvrAppBar visible={visible} open={open} openDrawer={openDrawer} closeDrawer={closeDrawer} />
          <div className={visible ? classes.content : classes.viewer}>
            <div className={visible ? classes.appBarSpacer : null} />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/project/new" component={CreateProject} />
              <Route exact={false} path="/project/:id" strict={false} component={Project} />
              <Route exact path="/project/:id/materials" component={ProjectMaterials} />
              <Route exact path="/login" component={Front} />
              <Route exact path="/recover" component={RecoverPassword} />
              <Route exact path="/home" component={Home} />
              <Route path="/collaborate/:sessionId" component={this.SessionRedirect} />
              <PrivateRoute exact path="/users" component={Users} />
              <PrivateRoute exact path="/tasks" component={Tasks} />
              <PrivateRoute exact path="/account" component={Account} />
            </Switch>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    open: state.appbar.open,
    auth: state.auth.isAuthenticated,
    visible: state.appbar.visible
  }
}

function mapDispatchToProps(dispatch) {
  return {
    joinSession: sessionId => dispatch(joinSessionRequest(sessionId)),
    openDrawer: () => dispatch(openDrawer()),
    closeDrawer: () => dispatch(closeDrawer())
  }
}

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DesignVr)))
