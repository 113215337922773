import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Protected from './protected'
import {Redirect} from 'react-router'

const styles = {
  flex: {
    flexGrow: 1
  }
}

class DvrLoginCard extends React.Component {
  constructor(props) {
    super(props)
    this.classes = props
  }

  render() {
    const {classes} = this.classes
    return (
      <React.Fragment>
        <Protected>
          <Redirect to="/home" />
        </Protected>
        <Card>
          <CardContent>
            <Typography>Log in to DesignVR</Typography>
            <Button onClick={this.props.doLogin}>Google</Button>
            <Button onClick={this.props.doLogin}>LinkedIn</Button>
            <Button onClick={this.props.doLogin}>Facebook</Button>
          </CardContent>
          <CardActions>
            <Button>Recover Password</Button>
          </CardActions>
        </Card>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(DvrLoginCard)
