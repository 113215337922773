import React from 'react'
import ReactDOM from 'react-dom'
import {withStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const styles = theme => ({
  formElement: {
    margin: '20px 0 20px 0' //`${theme.spacing.unit}px 0 ${theme.spacing.unit}px 0`
  },
  formElementWithButton: {
    margin: '20px 0 6px 0' //`${theme.spacing.unit}px 0 ${theme.spacing.unit}px 0`
  }
})

class ShareSessionDialog extends React.Component {
  constructor(props) {
    super(props)
  }

  copyToClipboard = event => {
    const copyText = document.getElementById("url");
    copyText.select();
    document.execCommand("copy");
  }

  render() {
    const {classes, open, handleClose} = this.props

    return (
			<Dialog
				open={this.props.open}
				onClose={this.props.handleClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Share design session</DialogTitle>
				<DialogContent>
					<DialogContentText>
            Invite others to collaborate with this link and share changes in real time.
					</DialogContentText>
         
					<TextField
            className={classes.formElementWithButton}
						id="url"
						label="Collaboration URL"
						type="text"
						fullWidth
            value={'https://' + window.location.hostname + '/collaborate/' + this.props.sessionId}
            InputProps={{
              readOnly: true,
            }}
					/><Button variant="contained" size="small" onClick={this.copyToClipboard}>Copy</Button>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.props.handleClose} color="secondary">Cancel</Button>
					<Button onClick={this.props.handleClose} color="primary">Share</Button>
				</DialogActions>
			</Dialog>
    )
  }
}

export default withStyles(styles)(ShareSessionDialog)
