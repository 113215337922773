import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import {Link} from 'react-router-dom'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ShareIcon from '@material-ui/icons/Share'
import classnames from 'classnames'
import Protected from './protected'
import ALYRoles from '../aly-roles'
import { matchPath } from "react-router";
import {history} from '../store'

const styles = {
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  media: {
    objectFit: 'cover',
  },
  card: {
    marginBottom: '1em',
    maxWidth: 345
  }
}

const defaultProjects = [
  {projectId: 'luxe-hotel-room-4k', status: 'pending', name: 'LUXE Hotel', description: 'LUXE Hotel', views: 100, configuration: 10, thumbnail: 'https://s3.amazonaws.com/dvr-panobuilder-renders/luxe-hotel-room/thumbnails/c84d3cd9abd91339ef14bf947223e06bcce4d2b6.png'},
  {projectId: 'luxe-office-4k', status: 'pending', name: 'LUXE Office', description: 'LUXE Office', views: 100, configuration: 10, thumbnail: 'https://s3.amazonaws.com/dvr-panobuilder-renders/luxe-office-4k/thumbnails/023874563106914d4488ef26e0fd2e78d696cc1b.png'},
  {projectId: 'millcreek-4-4k-2', status: 'pending', name: 'Millcreek', description: 'Millcreek', views: 100, configuration: 10, thumbnail: 'https://s3.amazonaws.com/dvr-panobuilder-renders/millcreek-4-4k-2/thumbnails/131d5c149207d430708b54ed9d2fd063f43aa6bc.png'},
  {projectId: 'cawley-465-4k-2', status: 'pending', name: 'Cawley', description: 'Cawley', views: 100, configuration: 10, thumbnail: 'https://s3.amazonaws.com/dvr-panobuilder-renders/cawley-465-4k-2/thumbnails/d913bbfea64def8217d944fbc6aab3c396b7c4b9.png'},
  {projectId: 'brimstone-4k-2', status: 'pending', name: 'Brimstone', description: 'Brimstone', views: 100, configuration: 10, thumbnail: 'https://s3.amazonaws.com/dvr-panobuilder-renders/brimstone/thumbnails/c449c39cc0cf14e96c566df553596a28ebbd9573.png'},
]

class DvrProjectList extends React.Component {
	// handleExpandClick() {
  //  // window.location.reload()
	// }
  componentDidMount(){
    this.forceUpdate();
  }

  render() {
    const {classes} = this.props
    const projects = this.props.projects ? this.props.projects : defaultProjects
    return (
      <React.Fragment>
        {projects.map(project => {
          return (
            <Card className={classes.card} key={project.projectId} onClick={()=>history.push('/project/' + project.projectId)}>
                <CardMedia
                  component="img"
                  alt={project.name}
                  className={classes.media}
                  image={project.thumbnail}
                  height="140"
                  title={project.name}
                />
                <CardContent>
                  <Typography component="p">
                    {project.description}
                  </Typography>
                </CardContent>
                <CardActions className={classes.actions} disableActionSpacing>
                  <Protected roles={[ALYRoles.Administrator]}>
                    <IconButton aria-label="Add to favorites">
                      <FavoriteIcon />
                    </IconButton>
                    <IconButton aria-label="Share">
                      <ShareIcon />
                    </IconButton>
                    <IconButton
                      aria-label="Show more"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </Protected>
                </CardActions>
            </Card>
          )
        })}
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(DvrProjectList)

