import React from 'react'
import {connect} from 'react-redux'
import {Route, Redirect} from 'react-router'

class Protected extends React.Component {
  render() {
    const Children = this.props.children
    return this.props.isAuthenticated === true ? this.props.children : (this.props.redirect ? <Redirect to={this.props.redirect} /> : null)
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Protected)
